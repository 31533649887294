*, *::before, *::after {
    box-sizing: border-box;
  }
  
  :root {
    --accent-color: rgb(204, 0, 0);
    --lightest-gray: rgb(244, 244, 244);
    --light-gray: rgb(120, 120, 120);
    --medium-gray: rgb(96, 96, 96);
    --dark-gray: rgb(13, 13, 13);
    --header-height: 60px;
    --animation-duration: 200ms;
    --animation-timing-curve: ease-in-out;
    --sidebar-closed-width: 60px;
    --sidebar-open-width: 300px;
    --bttv-rs-gray-0: #fff;
    --bttv-rs-gray-50: #e9ebf0;
    --bttv-rs-gray-100: #cbced4;
    --bttv-rs-gray-200: #a4a9b3;
    --bttv-rs-gray-300: #858b94;
    --bttv-rs-gray-400: #6a6f76;
    --bttv-rs-gray-500: #5c6066;
    --bttv-rs-gray-600: #3c3f43;
    --bttv-rs-gray-700: #292d33;
    --bttv-rs-gray-800: #1a1d24;
    --bttv-rs-gray-900: #0f131a;
    --bttv-rs-primary-50: #f6f2ff;
    --bttv-rs-primary-100: #e2d4ff;
    --bttv-rs-primary-200: #ccb0ff;
    --bttv-rs-primary-300: #b68cff;
    --bttv-rs-primary-400: #a269ff;
    --bttv-rs-primary-500: #9146ff;
    --bttv-rs-primary-600: #8331f5;
    --bttv-rs-primary-700: #721de0;
    --bttv-rs-primary-800: #5e0cc2;
    --bttv-rs-primary-900: #470099;
    --bttv-rs-color-red: #f04f43;
    --bttv-rs-color-orange: #ff9800;
    --bttv-rs-color-yellow: #ffc757;
    --bttv-rs-color-green: #58b15b;
    --bttv-rs-color-cyan: #00bcd4;
    --bttv-rs-color-blue: #1499ef;
    --bttv-rs-color-violet: #673ab7;
    --bttv-rs-red-50: #ffeded;
    --bttv-rs-red-100: #fccaca;
    --bttv-rs-red-200: #faa9a7;
    --bttv-rs-red-300: #f58884;
    --bttv-rs-red-400: #f26a63;
    --bttv-rs-red-500: #f04f43;
    --bttv-rs-red-600: #e63f30;
    --bttv-rs-red-700: #d12f1d;
    --bttv-rs-red-800: #b3200c;
    --bttv-rs-red-900: #8a1200;
    --bttv-rs-orange-50: #fff9f2;
    --bttv-rs-orange-100: #ffe2c2;
    --bttv-rs-orange-200: #ffce91;
    --bttv-rs-orange-300: #ffba61;
    --bttv-rs-orange-400: #ffa930;
    --bttv-rs-orange-500: #ff9800;
    --bttv-rs-orange-600: #f59700;
    --bttv-rs-orange-700: #e08e00;
    --bttv-rs-orange-800: #c27e00;
    --bttv-rs-orange-900: #960;
    --bttv-rs-yellow-50: #fff9f0;
    --bttv-rs-yellow-100: #ffedd1;
    --seventv-text-color-normal: #f0f0f0;
    --seventv-text-color-secondary: #f0f0f078;
    --seventv-input-background: #1c1c1c;
    --seventv-input-border: #4c4c4c;
    --seventv-input-background-highlight: #2b2b2b;
    --seventv-embed-background: #17171a;
    --seventv-embed-background1: #111114;
    --seventv-embed-background-highlight: #26262b;
    --seventv-embed-border: #121214;
    --seventv-embed-border1: #0d0d0f;
    --seventv-highlight-neutral-1: #8080803b;
    --seventv-background-transparent-1: #171717;
    --seventv-background-lesser-transparent-1: #191919;
    --seventv-background-transparent-2: #131313;
    --seventv-background-transparent-3: #111;
    --seventv-border-transparent-1: #ffffff1a;
    --seventv-background-shade-1: #171717;
    --seventv-background-shade-2: #131313;
    --seventv-background-shade-3: #111;
    --seventv-primary: #29b6f6;
    --seventv-accent: #66bb6a;
    --seventv-warning: #df4545;
    --seventv-muted: #999;
    --content-bg-color: #18181a;
    --accent-color-black: #151517;
    --bright-purple: #721de0;
  
    /*dark mode*/
    /* --text-color-normal: #000000;
    --background-color-normal: #18181a;
    --sidebar-bg-color-normal: #202023;
    --header-bg-color-normal: #1b1b1e;
    --section-border-color-normal: #151517;
    --content-bg-color-normal: rgb(19, 19, 20);
    --message-box-bg-color-normal: rgb(30, 31, 32); */
    
    --text-color-normal: #000;
    --background-color: #ffffff;
    --sidebar-bg-color: #ffffff;
    --header-bg-color: #ffffff;
    --section-border-color: #d0d0d0;
    --content-bg-color: #ffffff;
    --message-box-bg-color: #fff;

    /*light mode*/
    --text-color-normal-light: #000000;
    --background-color-light: #ffffff;
    --sidebar-bg-color-light: #ffffff;
    --header-bg-color-light: #ffffff;
    --section-border-color-light: #b3b3b3;
    --content-bg-color-light: #ffffff;
    --message-box-bg-color-light: #fff;

    /*Private Chatbot styling*/
    --almasi-green: #3E826D;
    --sidebar-new-chat-color: #3E826D;
    --sidebar-icon-color: #3E826D;
    --sample-prompt-bg-color: #f9fbfa;
    --sample-prompt-bg-color-hover: #ffffff;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text-color-normal-light);
    font-family: Plus Jakarta Sans;
  }
  
  @keyframes fadeInSlideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* .dark-mode {
    --text-color-normal: var(--text-color-normal);
    --background-color: var(--background-color-normal);
    --sidebar-bg-color: var(--sidebar-bg-color-normal);
    --header-bg-color: var(--header-bg-color-normal);
    --section-border-color: var(--section-border-color-normal);
    --content-bg-color: var(--content-bg-color-normal);
    --message-box-bg-color: var(--message-box-bg-color-normal);
    --menu-icon-color: var(--bttv-rs-gray-100);
    --menu-icon-highlight-color: var(--bttv-rs-gray-50);
    --sidebar-icon-color: var(--bttv-rs-gray-100);
    --sidebar-icon-highlight-color: var(--accent-color-black);
    --message-bot-bg-color: rgb(40, 40, 42);
    --sample-prompt-bg-color: rgb(24, 24, 26);
    --sample-prompt-border-color: rgb(49, 49, 49);
  }
  
  .light-mode {
    --text-color-normal: var(--bttv-rs-gray-600);
    --background-color: var(--background-color-light);
    --sidebar-bg-color: var(--sidebar-bg-color-light);
    --header-bg-color: var(--header-bg-color-light);
    --section-border-color: var(--section-border-color-light);
    --content-bg-color: var(--content-bg-color-light);
    --message-box-bg-color: var(--message-box-bg-color-light);
    --menu-icon-color: var(--bttv-rs-gray-100);
    --menu-icon-highlight-color: var(--bttv-rs-gray-50);
    --sidebar-icon-color: var(--bttv-rs-gray-100);
    --sidebar-icon-highlight-color: var(--bttv-rs-gray-50);
    --message-bot-bg-color: var(--bttv-rs-gray-50);
    --sample-prompt-bg-color: #ffffff;
    --sample-prompt-border-color: var(--section-border-color-light);
  } */

  .menu-icon-btn {
    background: none;
    border: none;
    padding: 0;
    width: var(--sidebar-closed-width);
  }
  
  .menu-icon1 {
    background: none;
    width: 25px;
    height: 25px;
    fill: var(--menu-icon-color);
    cursor: pointer;
  }
  
  .menu-icon-btn:focus {
    outline: none;
  }
  
  .menu-icon1:hover {
    fill: var(--menu-icon-highlight-color);
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, .4);
    height: var(--header-height);
    background-color: var(--header-bg-color);
    border-bottom: 1px solid var(--section-border-color);
    z-index: 2;
    font-size: 18px;
    color: var(--text-color-normal);
  }

  .header-left, .header-right {
    display: flex;
    align-items: center;
  }
  
  .header-right {
    margin-right: 10px;
  }
  
  .content {
    display: flex;
    background-color: var(--content-bg-color);
    color: var(--text-color-normal);
  }
  
  .sidebar {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--header-height)); 
    position: sticky;
    top: var(--header-height);
    left: 0;
    width: var(--sidebar-closed-width); 
    transition: width var(--animation-duration) var(--animation-timing-curve);
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 5px;
    border-right: 1px solid var(--section-border-color);
    background-color: var(--sidebar-bg-color);
  }
  
  .sidebar.open {
    width: var(--sidebar-open-width);
    text-overflow: ellipsis;
  }
  
  .sidebar .hidden-sidebar {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: none;
    width: 100%;
    transition: opacity var(--animation-duration) var(--animation-timing-curve), width 0s var(--animation-duration);
  }
  
  .top-sidebar {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
  }
  
  .middle-sidebar {
    height: 420px; /* Set the desired fixed height */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    box-sizing: border-box; /* Ensure padding is included in the height */
}

/* Hides the scrollbar ? */
.middle-sidebar::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.middle-sidebar:hover::-webkit-scrollbar {
    width: 8px; /* Set the desired scrollbar width */
    height: 8px; /* Set the desired scrollbar height for horizontal scrollbars */
}

.middle-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); /* Customize scrollbar thumb color */
    border-radius: 10px; /* Customize scrollbar thumb shape */
}

.middle-sidebar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); /* Customize scrollbar track color */
    border-radius: 10px; /* Customize scrollbar track shape */
}

.middle-sidebar:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
}

  
  .bottom-sidebar {
    position: absolute;
    width: 100%;
    min-height: 0;
    bottom: 0;
    margin-top: 10px;
  }

  .sidebar-item-delete-btn {
    padding: 2px 4px;
    border: none;
    background: #eee;
    border-radius: 6px;

    font-size: 12px;
  }
  
  .sidebar-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: none;
    border: none;
    display: flex;
    width: 100%;
    align-items: stretch;
    padding: 0;

    -webkit-animation: scale-up-bl 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	  animation: scale-up-bl 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  
  .sidebar-item:focus {
    outline: none;
  }
  
  .sidebar-item:hover {
    border: none;
    border-left: 3px solid var(--almasi-green);
  }
  
  .sidebar-item-left, .sidebar-item-right {
    display: flex;
    padding: 5px;
    color: var(--sidebar-icon-color);
    align-items: center;
    background: none;
    border: none;
  }
  
  .sidebar-item-left {
    flex-shrink: 0;
    width: calc(var(--sidebar-closed-width) - 5px);
    justify-content: center; 
    text-align: center;
    border: none;
  }
  
  .sidebar-item:hover .sidebar-item-left,
  .sidebar-item:hover .sidebar-item-right {
    color: var(--almasi-green);
    border: none;
  }
  
  .sidebar-item-right {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Plus Jakarta Sans;
    font-weight: 500;
    /* color: #979797; */
    color:#000
  }
  
  .sidebar-icon {
    background: none;
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    
  }

  .Group {
      width: 300px;  /* Set the desired width */
      height: 400px; /* Set the desired height */
      border: 1px solid #ccc;  /* Optional: Add a border to visually see the box */
      overflow-y: auto;  /* Enable vertical scrolling */
      padding: 10px;  /* Optional: Add some padding */
  }
  

  main {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100vh - var(--header-height));
    padding: 20px;
    color: rgb(51, 51, 51);
  }

  .right-table {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
    padding-bottom: 1.5rem;
    padding-top: 7px;
    width: 210px;

}

.file-bubbles {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 200px;
}

.file-bubble {
  display: flex;
  position: relative; /* For positioning the delete button */
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background-color: var(--lightest-gray);
  border: 1px solid var(--section-border-color);
}

.file-info {
  display: flex;
  flex-direction: column;
  width: 100%; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Plus Jakarta Sans;
  
}

.file-name {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-info:hover .file-name {
  white-space: normal; /* Allow wrapping */
  overflow: visible;
  text-overflow: clip;
  word-break: break-all; /* Breaks long words */
  overflow-wrap: anywhere; /* Alternative: breaks long words */
}

.delete-btn {
  position: absolute;
  top: -5px; /* Adjust as needed */
  right: -1px; /* Adjust as needed */
  background: none;
  border: none;
  cursor: pointer;
  padding: 0; /* Remove default padding */
  width: 24px; /* Set width */
  height: 24px; /* Set height */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Make it round */
  background-color: var(--lightest-gray); /* Set background color */
}

.delete-btn:hover {
  background-color: var(--bttv-rs-gray-200); /* Changes background color on hover */
}

.delete-btn svg {
  width: 16px; /* Adjust the size as needed */
  height: 16px; /* Adjust the size as needed */
  stroke: var(--medium-gray); /* Set color to gray */
}

.delete-btn:hover svg {
  stroke: var(--dark-gray); /* Change color on hover */
}
.upload-btn {
  background-color: var(--almasi-green);
  color: #fff;
  border: 1px solid var(--almasi-green);
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 500;
}

.main-content-container {
  display: flex;
  flex-direction: row;
}

.right-section {
  font-family: Plus Jakarta Sans;
  display: flex;
  flex-direction: row;
  margin-left: 20px; /* Adjust the margin as needed */
}

.right-table {
  margin-right: 20px; /* Adjust the margin as needed */
}

.file-list {
  
  border-radius: 1rem;
  border-left: 1px solid #ddd;
  padding-left: 20px; /* Adjust the padding as needed */
  max-height: 90%; /* Adjust the max height as needed */
  max-width: 300px; /* Adjust the max width as needed */
  overflow-y: auto; /* Adds vertical scroll when content overflows */
}

.file-list h1 {
  font-size: 27px;
  font-weight: 600;
}

.file-list ul {
  list-style-type: none;
  padding: 0;
}

.file-list li {
  padding: 0.5rem 1rem;
  border: 1px solid var(--section-border-color);
  border-radius: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px; /* Adjust the max width as needed */
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
}

.file-list li:hover {
  white-space: normal;
  word-wrap: break-word;
  background: #f0f0f0;
  padding: 5px;
}

.file-list::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
  height: 6px; /* Adjust the height as needed */
}

.file-list::-webkit-scrollbar-thumb {
  background-color: #888; /* Adjust the color as needed */
  border-radius: 10px; /* Adjust the radius as needed */
}

.file-list::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Adjust the hover color as needed */
}

.file-list::-webkit-scrollbar-track {
  background: #f0f0f0; /* Adjust the track color as needed */
}

.file-list::-webkit-scrollbar-button {
  display: none; /* Hide the scrollbar arrows */
}


.upload-btn:disabled {
    background-color: var(--bttv-rs-gray-500);
}
  
.chatbox {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  flex-direction: column;
  color: rgb(51, 51, 51);
  z-index: 9999;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 10px;
  color: rgb(51, 51, 51);
}

.chat-messages::-webkit-scrollbar {
    display: none;
}

/* Show the scrollbar thumb when the content is hovered */
.chatbox:hover::-webkit-scrollbar {
    width: 8px; /* Set the desired scrollbar width */
    height: 8px; /* Set the desired scrollbar height for horizontal scrollbars */
}

/* Customize the scrollbar thumb */
.chatbox::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); /* Customize scrollbar thumb color */
    border-radius: 10px; /* Customize scrollbar thumb shape */
}

/* Customize the scrollbar track */
.chatbox::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); /* Customize scrollbar track color */
    border-radius: 10px; /* Customize scrollbar track shape */
}

/* Optional: Add hover effects for scrollbar thumb */
.chatbox:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
}

.right-table::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Show the scrollbar thumb when the content is hovered */
.right-table:hover::-webkit-scrollbar {
  width: 5px; /* Set the desired scrollbar width */
  height: 5px; /* Set the desired scrollbar height for horizontal scrollbars */
}

/* Customize the scrollbar thumb */
.right-table::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Customize scrollbar thumb color */
  border-radius: 10px; /* Customize scrollbar thumb shape */
}

/* Customize the scrollbar track */
.right-table::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Customize scrollbar track color */
  border-radius: 10px; /* Customize scrollbar track shape */
}

/* Optional: Add hover effects for scrollbar thumb */
.right-table:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}
/*
  .chat-messages::-webkit-scrollbar {
    display: none;    
  }
  
  .chat-messages::-webkit-scrollbar-thumb {
    background: rgb(181, 181, 181);
    border-radius: 32px;
    border: solid transparent;
    border-width: 20px 0;
  }
  
  .chat-messages::-webkit-scrollbar-track {
    background: none;
    padding: 30px 0px;
  }
  */

  .message-input {
    display: flex;
    flex-direction: row;
    background-color: var(--message-box-bg-color);
    border: 1px solid var(--section-border-color);
    align-items: center;
    justify-content: space-between;
    border-radius: 32px;
    padding: 5px 6px 5px 5px;
    gap: 5px;
    width: 100%;
    align-self: center;
    color: var(--text-color-normal);
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, .4);
    z-index: 2;
  }
  
  .message-input input {
    background: none;
    border: none;
    flex-grow: 1;
    border-bottom-left-radius: 32px;
    border-top-left-radius: 32px;
    padding: 10px;
    color: var(--text-color-normal);
    margin-right: 2px;
  }
  
  .message-input button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: var(--almasi-green);
    color: #fff;
    padding: 4px;
    border-radius: 32px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .message-sent {
    background-color: none;
    border-radius: 6px;
    padding: 20px;
    animation: fadeInSlideDown 0.5s ease-out forwards;
    font-size: 18px;
  }
  
  .message-response {
    background-color: #eee;
    border-radius: 6px;
    padding: 20px;
    animation: fadeInSlideDown 0.5s ease-out forwards;
    font-size: 18px;
  }
  
  button:focus-visible, input:focus-visible {
    outline: 2px solid var(--almasi-green);
    outline-offset: 2px;
  }
  
  .menu-icon-btn:focus-visible, .sidebar-item:focus-visible {
    outline: 2px solid var(--almasi-green); 
    outline-offset: -2px;
  }
  
  button:focus:not(:focus-visible), input:focus:not(:focus-visible) {
    outline: none;
  }
  
  .logo {
    width: 28.41px;
    height: 35px;
  }

  .logo-text {
    color: var(--almasi-green);
  }

  #main-content {
    color: var(--text-color-normal);
  }

  .welcome-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 32px; 
    padding: 20px;
    color: var(--text-color-normal);
  }
  
  .welcome-message h1 {
    font-size: 30px;
    font-weight: 600;
    font-family: Plus Jakarta Sans;
  }

  .sample-prompts-container ol li {
    padding: 5px;
  }
  
  .welcome-message .empty-message { 
    background: linear-gradient(to right, rgb(37, 112, 217) 2%, #8e1bd6 10%, #b569bf, white 60%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 600;
    font-size: 42px;
  }
  
  .welcome-message, .welcome-message .empty-message {
    animation: fadeInSlideDown 2s ease-out forwards;
  }

  .welcome-logo {
    background-image: url('./App/Img/chat-logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 84px;
    height: 84px;
    margin-bottom: 10px;
  }
  
  .darklight-btn {
    border: none;
    background-color: var(--bright-purple);
    color: #fff;
    padding: 8px;
    border-radius: 6px;
    font-size: 16px;
  }
  
  .darklight-btn:focus {
    outline: none;
  }
  
  .darklight-btn:focus-visible {
    outline: 2px solid #552b70; 
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loader {
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid var(--almasi-green); 
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    align-self: center;
  }
  
  .sample-prompts-container {
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    gap: 10px;
    margin-top: 80px;
    justify-content: space-around;
    flex-wrap: wrap;
    height: 500px;
    font-family: Plus Jakarta Sans;
  }

  .green {
    color: var(--sidebar-new-chat-color);
  }


.delete-session-btn {
  background: #fff;
  border-radius: 4px;
  border: 2px solid black;
  width: 100%;
}

.context-menu {
    box-shadow: 0 2px 5px rgba(0,0,0,0.15);
    border-radius: 4px;
    animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Team Members Container */
.team-members {
  max-height: 0;
  overflow-y: scroll;
  transition: max-height 0.3s ease-out;
}

.team-members.expanded {
  max-height: 200px; /* Adjust the height as needed */
}

.team-member-header, .team-member {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}

.team-member-column-left {
  flex: 1;
  text-align: left;
}

.team-member-column-right {
  flex: 1;
  text-align: right;
}

.team-member-header {
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.team-member {
  border-bottom: 1px solid #f0f0f0;
}

/* Scrollbar Styling */
.team-members::-webkit-scrollbar {
  width: 12px;
}

.team-members::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.team-members::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.team-members::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Button and Team Members Animation */
.team-container {
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  overflow: hidden;
}

.team-container.expanded {
  max-height: 300px;
  padding: 10px 0;
}

.button-container {
  transition: transform 0.3s ease-out;
}

.button-container.expanded {
  transform: translateY(-10px);
}

@-webkit-keyframes scale-up-bl {
0% {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}
}

@keyframes scale-up-bl {
0% {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}
}


/*File Upload Table
.file-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    font-family: Plus Jakarta Sans;
}

.file-table th, .file-table td {
    border: 1px solid var(--section-border-color);
    text-align: left;
}

.file-table th {
    background-color: var(--almasi-green);
    color: white;
    border: none;
}

.table-center {
    display: flex;
    text-align: center;
    justify-content: center;
}

.file-table td button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    
}

.file-table td button:hover {
    background-color: darkred;
}

.upload-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.upload-btn {
    background-color: rgb(255, 255, 255);
    color: #000;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 500;
}
*/
.modal-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 99999;
  width: 700px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: 500px;
}

.modal-content button {
  padding: 10px;
  background: #3e826d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:hover {
  background: #2e6b58;
}

.modal-content input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0; /* Adjust padding for better spacing */
  border-bottom: 1px solid #ddd;
}

ul li span {
  flex: 2; /* Email takes up more space */
  text-align: left;
  overflow-wrap: anywhere; /* Ensure long emails wrap */
}

ul li select {
  flex: 1; /* Dropdown adjusts based on available space */
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

ul li input[type="checkbox"] {
  flex: 0.5; /* Checkbox occupies minimal space */
  margin: 0 auto; /* Center align checkbox */
}

.file-name {
  max-width: 385px; /* Adjust the max width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.user-email {
  padding-left: 10px;
  margin-bottom: 10px;
}

.demo-sources {
  margin-top: 10px;
}

.header-right {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.model-select {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
}

.citation-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.citation-box {
  padding: 5px 10px;
  background-color: #3e826d;
  color: white;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  font-size: 0.9rem;
  
}

.citation-box:hover {
  background-color: #2a5d4a;
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-15%);
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: pre-wrap;
  z-index: 10;
  transition: opacity 0.2s, visibility 0.2s;
  width: 220px;
  max-height: 200px;
  overflow-y: auto;
  

}

.tooltip::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.tooltip::-webkit-scrollbar-track {
  background: transparent; /* Background color of the track */
  border-radius: 4px; /* Rounded edges for the track */
}

.tooltip::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5); /* Thumb color */
  border-radius: 4px; /* Rounded edges for the thumb */
  border: none; /* Optional border around the thumb */
}

.citation-box:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.model-id {
  display: block;
  margin-top: 10px;
  font-size: 0.75rem;
  color: #666; /* Optional: Use a light gray color */
  text-align: left; /* Align it to the right within the container */
}

.new-chat-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3E826D; /* Green */
  color: white;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

/* When sidebar is collapsed */
.new-chat-button.collapsed {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
}

/* Show SVG when collapsed */
.new-chat-button.collapsed svg {
  display: block;
}

/* Hide the button text when collapsed */
.new-chat-button.collapsed .button-text {
  display: none;
}

/* When sidebar is expanded */
.new-chat-button.expanded {
  width: 90%;
  height: 45px;
  border-radius: 25px;
  padding: 0 20px;
  justify-content: center; /* Center text */
}

/* Hide SVG when expanded */
.new-chat-button.expanded svg {
  display: none;
}

/* Show the button text when expanded */
.new-chat-button.expanded .button-text {
  display: block;
  margin-left: 0; /* Remove extra spacing */
}

.dots-icon {
  background-image: url('./App/Img/dots.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: 10px;
  align-self: center;
}

/* Make sure the modal appears on top */
.small-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}

/* Ensure modal box is interactive and sits on top */
.small-modal-box {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  text-align: center;
  z-index: 100001;
  position: relative;
}

/* Ensure buttons are also clickable */
.small-modal-box button {
  display: block;
  margin: 10px auto;
  padding: 8px 15px;
  background-color: #3E826D;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 100002;
  width: 180px;
}

.small-modal-box button:hover {
  background-color: #2f6653;
}

.private-thumbs-down{
  background-image: url('./App/Img/thumbs-down.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  width: 17px;
  height: 17px;
  border: none;
}

.private-thumbs-down:focus{
  outline: none;
}

.private-thumbs-up{
  background-image: url('./App/Img/thumbs-up.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  width: 17px;
  height: 17px;
  
  border: none;
}

.private-thumbs-up:focus{
  outline: none;
}

@media only screen and (max-width: 1000px) {
    .chatbox {
        width: 80%;
    }

    .sample-prompts-container {
        margin-top: 50px;
    }
  
}