body {
  
  font-family: Arial, sans-serif;
  /*font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;*/
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
  /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F2F6F5;
  ;
}


/*STYLING FROM HERE*/
.grid-layout {
  margin: 0;
  display: grid;
  grid-template-columns: 0.17fr 1fr;
  grid-template-rows: 1fr auto; /* Updated to include a third row for the chatbar */
  grid-template-areas:
    "sidebar main"
    "sidebar chatbar"; /* Added the chatbar to the grid areas */
  min-height: 100vh;
}

sidebar {
  grid-area: sidebar;
  background: #FFFFFF;
  padding: 10px;
  z-index: 2;
}

main {
  grid-area: main;
  background-color: #F2F6F5;
  color: #101010;
  display: flex;
  justify-content: center;
  width: 100vw;
}

chatbar {
  grid-area: chatbar;
  background-color: #F2F6F5;
  padding: 10px;
  color: #fff;
  width: 100vw;
}

/*CHATBAR*/
.chatbar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  
}

.fetching-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
}

.cancel-button {
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #e94242;
  background: #ed5b5b;
  color: #fff;
  font-family: Plus Jakarta Sans;
  margin-top: 10px;
}

.cancel-button:focus {
  outline: none;
}

.chatbar-box {
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #0000001A;
  background: #fff;
  outline: transparent solid 1px;
  width: 60%;
  height: 55px;
  border-radius: 38px;
  padding: 5px;
  box-shadow: 0px 3px 10px 0px #00000033;
  -webkit-box-shadow: 0px 3px 10px 0px #00000033;
  -moz-box-shadow: 0px 3px 10px 0px #00000033;
}

.chatbar-box:hover{
  box-shadow: 0px 3px 10px 0px #00000035;
}

.switch-answer-button {
  background: transparent;
  border: none;
  font-family: Plus Jakarta Sans;
  color: #979797;
  font-size: 12px;
}

.switch-answer-button:focus {
  outline: none;
}

.input-chat {
  background: none;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 16px;
  padding: 10px;
  color: #333;
  resize: none;
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  line-height: 1.75;
  align-items: center;
}

.input-chat:active {
  outline: none;
}

.input-chat:focus {
  outline: none;
}

.button-space {
  padding: 5px;
  width: 10%;
  height: 100%;
}

.question-type-dropdown {
  background-color: #ffffff;
  border: solid #d4d4d4 1px;
  height: 100%;
  border-radius: 10px;
  max-width: 12%;
  min-width: 75px;
  text-align: center;
}

.question-type-dropdown:active {
  outline: none;
}

.question-type-dropdown:focus {
  outline: none;
}

/*MAIN DISPLAY/CHAT HISTORY BELOW*/
.default-chat-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 13px;
  height: 100%;
  
}

.default-chat-logo {
  background-image: url('./App/Img/chat-logo.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 84px;
  height: 84px;
  margin-bottom: 10px;
}

.default-chat-container h1 {
  font-family: Plus Jakarta Sans;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 100px;
}

.default-chat-container h2 {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}

.default-chat-container button {
  background: #f9fbfa;
  border: 1px solid #0000001A;
  border-radius: 16px;
  padding: 14px 20px 14px 20px;
  width: 95%;
  margin-bottom: 10px;
  text-align: start;
  font-family: Plus Jakarta Sans;
  color: #898797;
}

.default-chat-container button:focus {
  outline: none;
}

.expandable-button {
  border: none;
  background: none;
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 0;
}

.default-chat-container button:hover {
  background: #ffffff;
  outline: none;
}

.chat-container {
  display: flex;
  flex-direction:column-reverse;
  
  max-height: 90vh;
  overflow-y: auto;
  width: 60%;
  
}

.chat-container::-webkit-scrollbar {
  display: none; /* Width of the entire scrollbar */
  
}

.item {
  background: #333;
  color: #fff;
  padding: 10px;
}


.chat-primaryfile {
  font-size: 12px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: solid rgb(152, 152, 152) 1px;
  color: black;
}

.chat-primaryfile a{
  text-decoration: none;
  color: #0075FF;
  font-size: 12px;
}

.chat-primaryfile ul{
  padding: 5px;
  margin: 0;
}

.source-icon{
  background-image: url('./App/Img/source-icon.png');
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  
}

.feedback-modal{
  position: absolute;
  bottom: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  width: 30%;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 10px;
  margin-left: 10px;
  box-shadow: 0px 3px 5px 0px #00000033;
}

.feedback-modal textarea{
  border-radius: 14px;
  border: 1px solid #0000001A;
  margin-bottom: 5px;
  padding: 5px;
  font-size: 12px;
}

.feedback-modal textarea:focus{
  border: 1.5px solid #12A37F;
  outline: none;
}

.feedback-modal button{
  border: 1px solid #0000001A;
  border-radius: 14px;
  background: #12A37F;
  padding: 5px;
  color: #fff;
  font-size: 12px;
}

.feedback-modal button:focus{
  outline: none;
}

.feedback-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.feedback-icon-flex {
  display: flex;
  flex-direction: row;
  width: 89%;
}

.thumbs-up{
  background-image: url('./App/Img/thumbs-up.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  
  border: none;
}

.thumbs-up:focus{
  outline: none;
}

.copy-icon{
  background-image: url('./App/Img/copy.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: none;
}

.copy-icon:focus{
  outline: none;
}

.thumbs-down{
  background-image: url('./App/Img/thumbs-down.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: none;
}

.thumbs-down:focus{
  outline: none;
}

.refresh-icon{
  background-image: url('./App/Img/refresh.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: none;
}

.refresh-icon:focus{
  outline: none;
}

.chat-assistant-container{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: flex-end;
}

.profile-assistant-icon{
  background-image: url('./App/Img/profile-assistant.png');
  background-repeat: no-repeat;
  background-size:cover;
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.chat-user-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: flex-end;
}

.profile-user-icon{
  background-image: url('./App/Img/profile-user.png');
  background-repeat: no-repeat;
  background-size:cover;
  width: 40px;
  height: 40px;
  margin-left: 15px;
}

.chat-secondaryfiles {
  font-size: 12px;
  color: black;
}

.chat-text {
  font-size: 15px;
  font-family: Plus Jakarta Sans;
}

.user-text {
  font-size: 15px;
  font-family: Plus Jakarta Sans;
}

.user-text p {
  margin-bottom: 0;
}

.chat-text h3 {
  font-size: 14px;
  font-weight: 600;
}

.chat-text h2 {
  font-size: 16px;
}

.chat-text h1 {
  font-size: 16px;
}

.chat-text h4 {
  font-size: 16px;
}

/* FIGMA TEST */


/*SIDEBAR */



.toggle-sidebar-button {
  background-color: #eeeeee;
  border: none;
  border-radius: 4px;
  padding: 5px;
  color: #bcbcbc;
  left: 0;
  bottom: 0;
  position: absolute;
  margin-bottom: 5px;
  margin-left: 5px;
  padding: 10px;
  box-shadow: 0px 0px 3px 0px rgba(60, 60, 60, 0.75);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(60, 60, 60, 0.75);
  -moz-box-shadow: 0px 0px 3px 0px rgba(60, 60, 60, 0.75);
}

.toggle-sidebar-button:active {
  outline: none;
}

.toggle-sidebar-button:focus {
  outline: none;
}

.toggle-sidebar-button:hover {
  background: linear-gradient(to bottom right, #7441a4, #8869a5);
}

.sidebar-button {
  border: none;
  border-radius: 6px;
  padding: 5px;
  color: #fff;
  font-weight: bold;
  text-align: left;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
}

.sidebar-button:hover {
  background-color: rgba(0, 0, 0, 0.5); /* Default color with transparency on hover */
}

.sidebar-button:active {
  outline: none;
}

.sidebar-button:focus {
  outline: none;
}

.vectordb-message {
  background-color: #333;
  color: #fff;
  padding: 10px;
  margin-top: 10px;
  border-radius: 6px;
  font-size: 13px;
  text-align: left;
  overflow-y: auto;
  max-height: 500px;
}

/*COLORS*/
.blue {
  background-color: #0066ff;
}

.red {
  background-color: #ff4000;
}


/*Tooltip test*/
.title-tooltip {
  color: #000;
  position: relative;
  display: inline-block;
}

.text-tooltip {
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  display: none;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 10px;
  width: 300px;
  overflow-wrap: break-word;
  word-break:keep-all;
  white-space: normal;
  box-shadow: 0px 0px 3px 0px rgba(60, 60, 60, 0.75);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(60, 60, 60, 0.75);
  -moz-box-shadow: 0px 0px 3px 0px rgba(60, 60, 60, 0.75);
}



.title-tooltip:hover .text-tooltip {
  display: block; 
}

/*model select*/
.model-selector {
  
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 5px;
}

.model-selector select {
  border: none;
  outline: none;
  background: none;
  padding: 5px;
  margin: 0;
  border-radius: 6px;
  font-weight: bold;
  color: #6c6c6c;
  
}

.system-prompt-textarea {
  min-height: 300px;
}

.input-login {
  border-radius: 6px;
}

.login-back-button {
  display: flex;
  position: absolute;
  top: 0;
  left: 0; 
  padding: 20px;
  align-items: center;
  font-weight: 500;
  font-family: Plus Jakarta Sans;
  color: #898797;
}

.back-arrow {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #898797;
  border-left: 2px solid #898797;
  transform: rotate(-45deg);
  margin-right: 5px;
  position: relative;
  top: -1px;
}

/* NEW STYLING */

/*sidebar content*/
.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  
}

.sidebar-top-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebar-bottom-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebar-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.logo-text {
  font-family: Bellefair;
  font-size: 26px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #3E826D;
  align-self: center;
}

.logo-text a {
  text-decoration: none;
  color: #3E826D;
}

.newchat-button {
  font-family: Plus Jakarta Sans;
  width: Fixed (274px);
  height: Hug (40px);
  top: 92px;
  left: 20px;
  padding: 8px 39px 8px 39px;
  border-radius: 45px;
  gap: 10px;
  background: #3E826D;
  color: #fff;
  text-align: center;
  margin-top: 30px;
  border: none;
}

.newchat-button:active {
  outline: none;
}

.newchat-button:focus {
  outline: none;
}

.logo {
  background-image: url('./App/Img/newlogo.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 28.41px;
  height: 35px;
}

.sponsor-logo {
  background-image: url('./App/Img/sponsor.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 170px;
  height: 70px;
  margin-bottom: 10px;
}

.session-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  font-family: Plus Jakarta Sans;
  overflow-y: auto;
  max-height: 650px;
  padding-right: 5px;
}

.session-container::-webkit-scrollbar-track {
  
  border-radius: 16px;
  margin-top: 20px;
}

.session-container::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: #dddddd;
  margin-top: 20px;
}

.session-container::-webkit-scrollbar {
  width: 4px;
}

.profile-button {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  background: #fff;
  padding: 10px;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: Plus Jakarta Sans;
  font-weight: 500;
  border: 1px solid transparent;
  width: 100%;
}

.profile-button:focus {
  outline: none;
}

.profile-button:active {
  outline: none;
}

.profile-button:hover {
  border: 1px solid #D2EEE6;
}

.logout-wrapper {
  position: relative;
  
}

.login-button {
  display: flex;
  flex-direction: row;
  background-color: #F2F6F5;
  border: none;
  border-radius: 6px;
  padding: 10px;
  color: #000;
  font-weight: 500;
  margin-top: 5px;
  font-size: 13px;
  font-family: Plus Jakarta Sans;
  width: 100%;
  text-align: flex-start;
}

.login-button:active {
  outline: none;
  background: #fff;
}

.login-button:focus {
  outline: none;
  background: #fff;
}

.login-button:hover {
  background: #fff;
}

.login-modal {
  position: absolute;
  bottom: 52px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 5px;
  background: #F2F6F5;
  color: #fff;
  font-family: Plus Jakarta Sans;
  width: 100%;
  -webkit-box-shadow: 0px 2px 3px 0px #00000033;
  -moz-box-shadow: 0px 2px 3px 0px #00000033;
  box-shadow: 0px 2px 3px 0px #00000033;
}

.chatsession-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
}

.chatsession-button {
  display: flex;
  flex-direction: row;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 10px;
  background: #fff;
  font-size: 13px;
  width: 100%;
  margin-bottom: 2px;
  color: #979797;
  align-items: center;
  justify-content: space-between;
}

.chatsession-button:active {
  outline: none;
  border: 1px solid #D2EEE6;
  color: #3E826D;
}

.chatsession-button:focus {
  outline: none;
  border: 1px solid #D2EEE6;
  color: #3E826D;
}

.chatsession-button:hover {
  outline: none;
  border: 1px solid #D2EEE6;
}

.expand-button {
  background: transparent;
  border: none;
  color: #979797;
}

.expand-button:focus{
  outline: none;
}

.chatsession-item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: Plus Jakarta Sans;
}

.chatsession-options-button {
  background: none;
  border: none;
  padding: 5px;
}

.chatsession-options-button:focus {
  outline: none;
}

.chatsession-icon {
  background-image: url('./App/Img/chat_session.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
  
}

.dots-icon {
  background-image: url('./App/Img/dots.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 4px;
  height: 15px;
  
}

.delete-modal {
  position: absolute;
  top: 50%; 
  right: 0; 
  background: #fff;
  border: 1px solid #D2EEE6;
  border-radius: 6px;
  padding: 5px;
  z-index: 100; 
  display: flex;
  flex-direction: column;
}

.delete-modal-button {
  border: 1px solid transparent;
  background: #fff;
  color: #979797;
  font-size: 13px;
  padding: 5px;
  min-width: 70px;
}

.delete-modal-button:hover {
  border: 1px solid #D2EEE6;
  border-radius: 6px;
}

.delete-modal-button:focus {
  outline: none;
}

.chatsession-title {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  color: #979797;
  border: none;
  margin-bottom: 5px;
}

.sessionbutton-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.avatar-icon {
  background-image: url('./App/Img/Avatars.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.send-icon {
  background-image: url('./App/Img/send.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 18.21px;
  height: 16.69px;
}

.button-chat {
  display: flex;
  min-width: 75px;
  height: 100%;
  border-radius: 30px;
  border: none;
  outline: transparent solid 1px;
  background-color: #3E826D;
  color: #fff;
  align-items: center;
  justify-content: center;
}

.button-chat:active {
  outline: none;
}

.button-chat:focus {
  outline: none;
}


.user-chatbox {
  padding: 15px;
  max-width: 85%;
  border-radius: 24px 24px 0px 24px;
  box-sizing: border-box;
  color: #fff;
  background: #3E826D;
  -webkit-box-shadow: 0px 3px 5px 0px #00000033;
  -moz-box-shadow: 0px 3px 5px 0px #00000033;
  box-shadow: 0px 3px 5px 0px #00000033;
}

.assistant-chatbox {
  padding: 15px;
  align-self: flex-start;
  
  max-width: 85%;
  background: #fff;
  
  border-radius: 24px 24px 24px 0px;
  box-sizing: border-box;
  color: #333;
  -webkit-box-shadow: 0px 3px 5px 0px #00000033;
  -moz-box-shadow: 0px 3px 5px 0px #00000033;
  box-shadow: 0px 3px 5px 0px #00000033;
}

/* LOGIN CSS */
.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Plus Jakarta Sans;
}

.verify-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Plus Jakarta Sans;
}

.verify-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 500px;
  background: none;
}

.verify-container h1{
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.05em;
  text-align: center;
}

.verify-container p{
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #898797;
}

.verify-container-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 13px;
  width: 418px;
  height: 56px;
  padding: 13px, 140px, 13px, 140px;
  border-radius: 16px;
  border: 1px solid #3E826D;
  box-shadow: 0px 2px 3px 0px #1018280D;
  background: #3E826D;
  color: #fff;
  margin-bottom: 10px;
}



.verify-container button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 13px;
  width: 418px;
  height: 56px;
  padding: 13px, 140px, 13px, 140px;
  border-radius: 16px;
  border: 1px solid #0000001A;
  box-shadow: 0px 2px 3px 0px #1018280D;
  background: #FFFFFF;
  margin-bottom: 10px;
}

.verify-container button:focus {
  outline: none;
}

.verify-container button:active {
  outline: none;
}

.verify-container a{
  text-decoration: none;
  color: #3E826D;
  
}

.gmail-icon {
  background-image: url('./App/Img/gmail.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 17.47px;
  height: 13.11px;
  margin-right: 10px;
}

.login-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 500px;
  background: none;
}

.login-row-age {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 400px;
  background: none;
  color: #898797;
  font-size: 16px;
  font-weight: 400;
  font-family: Plus Jakarta Sans;
  padding: 10px;
  gap: 10px;
  
}

.login-row-newsletter {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 400px;
  background: none;
  color: #898797;
  font-size: 16px;
  font-weight: 400;
  font-family: Plus Jakarta Sans;
  padding: 10px;
  gap: 20px;
  
}

.login-row-newsletter label {
  align-self: center;
}

.login-row-age a {
  text-decoration: none;
  color: #898797;
  font-weight: 500;
}

.input-placeholder {
  color: #565656; 
}

.input-placeholder::placeholder {
  color: #565656;
}

.login-row-age input {
  align-self: flex-start;
  
}

.age-checkbox {
  width: 30px;
  height: 30px;
}

.newsletter-checkbox {
  width: 17px;
  height: 17px;
}

.login-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  gap: 10px;
}

.login-container select {
  margin: 10px 0;
  padding: 8px;
  width: 80%;
  border-radius: 14px;
  border: 1.5px solid #0000001A;
}

.login-container select:focus {
  outline: none;
}

.login-container input {
  margin: 10px 0;
  padding: 8px;
  width: 80%;
  border-radius: 14px;
  border: 1.5px solid #0000001A;
  
}

.login-container input:focus {
  border: 1.5px solid #12A37F;
  outline: none;
}

.login-container h1{
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.05em;
  text-align: center;

}

.login-container p{
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #898797;
  width: 80%;
}

.login-container button {
  width: 80%;
  padding: 10px;
  background-color: #12A37F; 
  color: white;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  margin-top: 5px;
}

.login-container button:focus {
  outline: none;
}

.login-container button:active {
  outline: none;
}

.input-login {
  background-color: #ffffff;
  border: 1.5px solid #12A37F;
  margin-bottom: 5px;
  border-radius: 14px;
  padding: 5px;
}

.input-login:active {
  outline: none;
}

.input-login:focus {
  outline: none;
}

.alternative-login {
  display: flex;
  width: 100%;
  justify-content: center;
}

.alternative-login button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff !important;
  color: #110E20 !important;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  width: 80%;
  font-weight: 500;
  border: 1px solid #0000001A;
}

.alternative-login button:focus {
  outline: none;
}

.google-icon{
  background-image: url('./App/Img/google.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.sidebar-login-button {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  background: #fff;
  padding: 15px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid transparent;
  font-family: Plus Jakarta Sans;
}

.sidebar-login-button:focus {
  outline: none;
}

.sidebar-login-button:active {
  outline: none;
}

.sidebar-login-button:hover {
  border: 1px solid #D2EEE6;
  outline: none;
}

.help-icon{
  background-image: url('./App/Img/user-question.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.help-text {
  margin-top: 2px;
  align-self: flex-end;
}

.help-text a {
  text-decoration: none;
  color: #979797;
}

.sep-line {
  border-top: 1px solid #E3E3E3;  
}

.sidebar-help-button {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  background: #fff;
  padding: 5px;
  justify-content: flex-start;
  color: #979797;
  font-size: 13px;
  font-family: Plus Jakarta Sans;
  border: 1px solid transparent;
  margin-top: 10px;
}

.sidebar-poc-button {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  background: #fff;
  padding: 5px;
  justify-content: flex-start;
  color: #979797;
  font-size: 13px;
  font-family: Plus Jakarta Sans;
  border: 1px solid transparent;
  margin-bottom: 10px;
}

.sidebar-help-button:focus {
  outline: none;
}

.sidebar-help-button:active {
  outline: none;
}

.sidebar-help-button:hover {
  outline: none;
}

.elipse {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 74px;
  border: 1px solid #0000001A;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0px 3px 10px 0px #0000000D;
  margin-bottom: 20px;
}

.login-logo {
  background-image: url('./App/Img/newlogo.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 33.53px;
  height: 43.78px;
}

.login-container-2 {
  display: flex;
  justify-content: flex-start;
  width: 80%;
}

.login-container-2 a{
  text-decoration: none;
  color: #12A37F;
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-left: 2px;
  font-weight: bold;
}

.login-footer {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  align-items: center;
  font-size: 13px;
}

.login-footer a {
  align-items: center;
  margin-left: 2px;
  text-decoration: none;
  color: #12A37F;
  font-weight: bold;
  font-size: 13px;
}

/* TOAST CONTAINER STYLING */
.subscription-alert {
  position: absolute;
  display: flex;
  top: 0;
  margin-top: 100px;
  background: #fcce37;
  border: 1px solid #ffcb22;
  color: rgb(255, 255, 255, 0.9);
  border-radius: 6px;
  font-family: Plus Jakarta Sans;
  font-size: 22px;
  font-weight: 500;
  padding: 15px 40px;
  height: 100px;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 3px 10px 0px #0000000D;
}

.subscription-alert a{
  
  color: #fff;
  font-weight: 700;
}

.toast-error {
  background: #ed5b5b;
  border: 1px solid #fca9a9;
  color: rgb(255, 255, 255, 0.9);
  border-radius: 6px;
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  padding-left: 10px;
  align-items: center;
  box-shadow: 0px 3px 10px 0px #0000000D;
}

.toast-success {
  background: #3E826D;
  border: 1px solid #a2d4c6;
  color: rgb(255, 255, 255, 0.9);
  border-radius: 6px;
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  padding-left: 10px;
  align-items: center;
  box-shadow: 0px 3px 10px 0px #0000000D;
}

.toast-warn {
  background: #fcce37;
  border: 1px solid #ffcb22;
  color: rgb(255, 255, 255, 0.9);
  border-radius: 6px;
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  padding-left: 10px;
  align-items: center;
  box-shadow: 0px 3px 10px 0px #0000000D;
}

.toast-info {
  background: #6193ff;
  border: 1px solid #5389ff;
  color: rgb(255, 255, 255, 0.9);
  border-radius: 6px;
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  padding-left: 10px;
  align-items: center;
  box-shadow: 0px 3px 10px 0px #0000000D;
}

.Toastify__close-button {
  color: rgba(255, 255, 255, 0.9) !important;
  opacity: 1 !important; 
  align-items: center !important;
  margin-right: 7px !important;
  line-height: 1.9 !important;
}

.Toastify__close-button:hover {
  outline: none;
}

.Toastify__close-button:focus {
  outline: none;
}

.Toastify__toast {
  min-height: 40px !important;
  min-width: 500px !important;
  align-items: center !important;
  justify-content: center !important;
}

/* FAQs style pages */
.faq-grid-layout {
  display: grid;
  grid-template-columns: auto; /* Single column layout */
  grid-template-rows: auto 0.4fr 1fr 0.3fr; /* Size rows to content, except for main which takes up remaining space */
  grid-template-areas:
    "navbar"
    "banner"
    "mainsection"
    "footer";
  height: 100vh; /* Full viewport height */
  font-family: Plus Jakarta Sans;
}



.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  grid-area: navbar;
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #eeeeee;
  height: 80px;
  z-index: 2;
}

.banner {
  grid-area: banner;
  background-color: #F2F6F5;
  color: black;
  font-family: Bellefair;
  height: 250px;
}

.mainsection {
  display: flex;
  flex-direction: column;
  grid-area: mainsection;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: black;
  padding: 1em;
  width: 100vw;
  font-family: Plus Jakarta Sans;
  justify-content: center;
  
}

.footer {
  grid-area: footer;

}

table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders between cells are merged */
}

th, td {
  border: 1px solid black; /* Sets the border around each cell */
  padding: 8px; /* Adds space between cell content and borders */
  text-align: left; /* Aligns text to the left */
}

th {
  font-weight: normal;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: flex-start;
  width: 50%;
  font-family: Plus Jakarta Sans;
  padding-top: 20px;
}

.content-container h1 {
  align-self: center;
  font-size: 20px;
}

.content-container h2 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 10px;
}

.content-container h3 {
  text-decoration: underline;
  align-self: center;
  font-size: 22px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.content-container h4 {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  align-self: center;
}

.content-container h5 {
  font-size: 20px;
  font-weight: 600;
  align-self: center;
}

.content-container iframe {
    align-self: center;
}

.content-table-center {
  align-items: center;
  align-self: center;
}

.resources-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 60%;
}

.terms-nav {
  margin-bottom: 50px;
  
}

.terms-nav a{
  font-weight: 500;
  text-decoration: none;
  color: #000;
}

.terms-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: flex-start;
  width: 50%;
  font-family: Plus Jakarta Sans;
  padding-top: 20px;
}

.terms-container h1 {
  align-self: center;
  font-size: 20px;
}

.terms-container h2 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 10px;
}

.terms-services-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: flex-start;
  width: 100%;
  gap: 10px;
}

.terms-services-row h1 {
  font-size: 16px;
  font-weight: 500;
  width: 5%;
  align-self: flex-start;
}

.terms-services-row p {
  font-size: 16px;
  width: 95%;
  align-self: flex-start;
}

.terms-license-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: flex-start;
  width: 100%;
  gap: 10px;
}

.terms-license-row h1 {
  font-size: 16px;
  font-weight: 500;
  width: 5%;
  align-self: flex-start;
}

.terms-license-row p {
  font-size: 16px;
  width: 95%;
  align-self: flex-start;
}

.terms-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: flex-start;
  width: 100%;
  gap: 10px;
}

.terms-info {
  width: 50%;
}

.terms-row h1 {
  font-size: 16px;
  font-weight: 600;
  width: 30%;
  align-self: flex-start;
}

.terms-row p {
  font-size: 16px;
  width: 70%;
  align-self: flex-start;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: flex-start;
  width: 50%;
  font-family: Plus Jakarta Sans;
  font-weight: 500;
  padding: 10px;
}

.main-container h1 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

.main-container a {
  color: #000;
  text-decoration: none;
}

.contactus-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: flex-start;
  width: 70%;
  font-family: Plus Jakarta Sans;
  font-weight: 500;
  padding: 10px;
}

.contactus-container h1 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
}

.faqs-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: flex-start;
  width: 50%;
  font-family: Plus Jakarta Sans;
  font-weight: 500;
  padding: 10px;
}

.faqs-container h1 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

.faqs-container h2 {
  font-size: 26px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 10px;
  
}

.faqs-container p {
  font-size: 16px;
  margin-bottom: 30px;
}

.faqs-container li {
  font-size: 16px;
}

.faqs-container a {
  color: #000;
}

.form-row {
  display: flex;
  flex-direction: column; /* Stack label and input vertically */
  gap: 5px; /* Space between label and input */
  width: 600px;
}

.form-row label, .form-row input, .form-row textarea {
  width: 100%; /* Ensure inputs and labels fill the form row */
  border-radius: 6px;
}

.form-row input, .form-row textarea {
  border: 1px solid #000;
  padding: 5px;
}

.form-row textarea {
  height: 200px;
}

.form-row input:focus, .form-row textarea:focus {
  outline: none;

}

.contactus-button {
  margin-top: 10px;
  align-self: flex-start; /* Align submit button to the start of the form */
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 5px;
  width: 100%;
  background: #3E826D;
  color: #fff;
}

.contactus-button:focus {
  outline: none;
}

.toggle-switch {
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

/* PRICING PAGE */
.pricing-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 10px;
  width: 100%;
}

.pricing-banner-info {
  border: 1px solid #DEDEDE;
  background-color: #ffffff;
  padding: 10px 30px;
  border-radius: 6px;
  text-align: center;
}

.pricing-item {
  display: flex;
  width: 397px;
  height: 620px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  
  border: 1px solid #DEDEDE;
  background: #FFF;
}

.pricing-item-enterprise {
  display: flex;
  width: 397px;
  height: 620px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  gap: 22px;
  border: 1px solid #DEDEDE;
  background: #3E826D;
  color: #fff;
}

.pricing-item h1 {
  color: #110E20;
  font-family: Bellefair;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
}

.pricing-item p {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
}

.pricing-item-enterprise h1 {
  color: #fff;
  font-family: Bellefair;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
}

.pricing-item-enterprise p {
  color: #fff;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
}

.pricing-features {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}

.pricing-features h1 {
  color: #110E20;
  font-family: Plus Jakarta Sans;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 450;
  
}

.pricing-features-enterprise {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.pricing-features-enterprise h1 {
  color: #fff;
  font-family: Plus Jakarta Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  
}

.pricing-details-enterprise {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.pricing-details-enterprise a {
  color: #000;
  text-decoration: none;
  display: flex;
  height: 50px;
  padding: 16px 16px 16px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 46px;
  background: var(--3-e-826-d, #fff);
  border: 1px solid transparent;
  color: #3E826D;
}

.pricing-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: stretch;
  
  height: 100%;
}

.pricing-details-monthly {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  height: 100%;
}

.pricing-details-monthly button {
  display: flex;
  height: 50px;
  padding: 16px 16px 16px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 46px;
  background: var(--3-e-826-d, #3E826D);
  border: 1px solid transparent;
  color: #fff;
}

.pricing-details-monthly button:focus {
  outline: none;
}

.button-white {
  background: var(--3-e-826-d, #eeeeee);
}

.pricing-button-container {
  display: flex;
  flex-direction: column;
  border: 1px solid red;
}

.pricing-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  height: 30px;
}

.pricing-price p {
  color: #898797;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-weight: 500;
  min-height: 30px;
}

.pricing-price h1 {
  color: var(--3-e-826-d, #3E826D);
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-weight: 700;
  min-height: 30px;
}

.pricing-details-enterprise button {
  display: flex;
  height: 50px;
  padding: 16px 16px 16px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 46px;
  background: var(--3-e-826-d, #fff);
  border: 1px solid transparent;
  color: #3E826D;
}

.pricing-details button {
  display: flex;
  height: 50px;
  padding: 16px 16px 16px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 46px;
  background: var(--3-e-826-d, #3E826D);
  border: 1px solid transparent;
  color: #fff;
}

.pricing-details button p {
  color: #E1FF01;
  text-align: justify;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
}

.pricing-details button:focus {
  outline: none;
}



/* NAVBAR COMPONENT */

.navbar-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
}

.navbar-item {
  position: relative;
  display: flex;
  flex-direction: row;
}

.navbar-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  justify-content: space-evenly;
  width: 500px;
  font-family: Plus Jakarta Sans;
}

.navbar a {
  text-decoration: none;
  color: #fff;
}

.navbar-links a {
  color: #000;
}

.menu-icon {
  display: none;
}

.navbar-item-button {
  display: flex;
  background: #3E826D;
  border: 1px solid #12A37F1A;
  border-radius: 30px;
  padding: 5px 20px 5px 20px;
  color: #fff;
}

.banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.banner-container p {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
}

.top-spacer {
  margin-top: 80px;
}

/* LANDING PAGE */
.landing-banner {
  display: flex;
  
  background: #fff;
  align-items: center;
  justify-content: center;
  font-family: Plus Jakarta Sans;
  padding-top: 65px;
  padding-bottom: 65px;
}


.landing-banner-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  width: 65%;
  gap: 50px;
}

.landing-banner-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 50%;
}

.landing-banner-item1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  align-self: center;
}

.landing-banner-text1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  gap: 20px;
}

.landing-banner-text1 h3 {
  font-size: 16px;
  font-weight: 700;
  padding-left: 3px;
  margin-bottom: -10px;
}

.landing-banner-text1 h1 {
  font-size: 30px;
}

.landing-banner-text1 a {
  text-decoration: none;
  color: #fff;
}

.landing-banner-text1 p {
  margin-bottom: 20px;
  margin-right: 20px;
}

.landing-banner-text1 h2 {
  font-size: 20px;
  
}

.landing-banner-text {
  display: flex;
  flex-direction: column;
  
  margin-top: 20px;
  gap: 20px;
}

.landing-banner-text h3 {
  font-size: 16px;
  font-weight: 700;
  padding-left: 3px;
  margin-bottom: -10px;
}

.landing-banner-text h1 {
  font-size: 32px;
}

.landing-banner-text a {
  text-decoration: none;
  color: #fff;
}

.landing-banner-text p {
  font-size: 18px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.landing-banner-text h2 {
  font-size: 20px;
  
}


.landing-banner-button {
  border-radius: 6px;
  background: #3E826D;
  align-items: center;
  text-align: center;
  padding: 10px 24px;
  max-width: 160px;
  color: #fff;
  border: 1px solid transparent;
  margin-top: -10px;
}

.landing-banner-button:focus {
  outline: none;
}

.landing-banner-pic {
  width: 100%;
  padding-top: 56.25%; /* 9 / 16 = 0.5625 */
  background-image: url('./App/Img/banner-pic.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.landing-banner-pic1 {
  width: 100%;
  padding-top: 56.25%; /* 9 / 16 = 0.5625 */
  background-image: url('./App/Img/banner-pic1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.landing-banner-pic2 {
  width: 100%;
  padding-top: 56.25%; /* 9 / 16 = 0.5625 */
  background-image: url('./App/Img/banner-pic2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.landing-banner-pic3 {
  width: 100%;
  padding-top: 56.25%; /* 9 / 16 = 0.5625 */
  background-image: url('./App/Img/banner-pic3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

/**/
.landing-main5 {
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  font-family: Plus Jakarta Sans;
  color: #000;
  padding-top: 30px;
  padding-bottom: 30px;
}

.landing-main5-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  width: 80%;
  
}

.landing-main5-container h3 {
  font-size: 16px;
  font-weight: 700;
  padding-left: 3px;
}

.landing-main5-container h2 {
  font-size: 24px;
  margin-top: 20px;
  font-weight: 600;
}

.landing-main5-container h1 {
  margin-bottom: 20px;
}

.landing-main5-container p.strong {
  font-weight: 700;
}

.landing-main4 {
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  font-family: Plus Jakarta Sans;
  color: #000;
  padding-top: 65px;
  padding-bottom: 65px;
}

.landing-main4-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  width: 80%;
  gap: 50px;
}

.landing-main4-container h3 {
  font-size: 16px;
  font-weight: 600;
}



.landing-main3 {
  display: flex;
  background: #3E826D;
  align-items: center;
  justify-content: center;
  font-family: Plus Jakarta Sans;
  color: #FFFFFF;
  padding-top: 65px;
  padding-bottom: 65px;
}

.landing-main3-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 70%;
  gap: 10px;
  
}

.landing-main3-container h1 {
  font-family: Plus Jakarta Sans;
  margin-bottom: 20px;
  font-size: 32px;
}

.landing-main3-container h3 {
  font-family: Plus Jakarta Sans;
  margin-bottom: 20px;
  font-size: 30px;
  align-self: center;
  text-align: center;
}

.landing-main3-container p {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 500;
}

.landing-main1 {
  display: flex;
  background: #3E826D;
  align-items: center;
  justify-content: center;
  font-family: Plus Jakarta Sans;
  color: #FFFFFF;
  align-items: center;
  padding-top: 65px;
  padding-bottom: 65px;
}


.landing-main1-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 65%;
  gap: 30px;
}

.landing-main1-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 42%;
  margin-right: 20px;
  gap: 60px;
}

.landing-main1-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.landing-main1-info-container h2 {
  font-size: 24px;
}

.landing-info-items {
  margin-right: 10px;
}

.landing-main1-text {
  color: #FFFFFF;
  font-family: Plus Jakarta Sans;
}

.landing-main1-text p {
  font-size: 18px;
}

.landing-main1-text h3 {
  font-size: 16px;
  font-weight: 700;
  padding-left: 3px;
}

.landing-main1-text h1 {
  font-size: 32px;
  font-weight: 700;
  padding-left: 3px;
}

.landing-main1-text h4 {
  font-size: 32px;
  font-weight: 700;
  padding-left: 3px;
  margin-bottom: 20px;
}

.landing-main1-text button {
  border-radius: 24px;
  background: #3E826D;
  align-items: flex-start;
  padding: 10px 24px;
  max-width: 150px;
  color: #fff;
  border: 1px solid transparent;
}

.landing-main1-text button:focus {
  outline: none;
}

.landing-main1-pic {
  width: 600px;
  height: 490px;
  flex-shrink: 0;
  background: #F2F6F5;
}

/* landing main2 */
.landing-main2 {
  display: flex;
  flex-direction: column;
  
  background: #FFFFFF;
  align-items: center;
  justify-content: center;
  gap: 30px;
  font-family: Bellefair;
  color: #000000;
  padding-top: 65px;
  padding-bottom: 65px;
}

.landing-main2-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 80%;
  gap: 30px;
}

.landing-main2-item {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.landing-main2-button2 {
  display: flex;
  padding: 27px 26px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: start;
  
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #fff;
  height: 418px;
  width: 413px;
  font-family: Plus Jakarta Sans;
}

.landing-main2-button2.active {
  background: #3E826D;
}

.landing-main2-button2 h1 {
  font-size: 24px;
  font-weight: 700;
}

.landing-main2-button2:focus {
  outline: none;
  background: var(--3-e-826-d, #3E826D);
  color: #FFFFFF;
}

.landing-main2-button2:focus h1 {
  color: #E1FF01;
}

.landing-main2-button1 {
  display: flex;
  padding: 27px 26px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: start;
  
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #fff;
  height: 418px;
  width: 413px;
  font-family: Plus Jakarta Sans;
}

.landing-main2-text {
  display: flex;
  flex-direction: column;
  height: 40%;
  
}

.landing-main2-text h1 {
  color: #000;
}

.landing-main2-text p {
  color: #000;
}

.landing-main2-button1.active {
  background: #3E826D;
}

.landing-main2-button1.active h1 {
  color: #E1FF01;
}

.landing-main2-button1.active p {
  color: #fff;
  
}

.landing-main2-button1 h1 {
  font-size: 24px;
  font-weight: 700;
}

.landing-main2-button1:focus {
  outline: none;
  background: var(--3-e-826-d, #3E826D);
  color: #FFFFFF;
}

.landing-main2-button1:focus h1 {
  color: #E1FF01;
}

/*NEWLETTER*/
.newsletter {
  display: flex;
  background: #3E826D;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.10);
  font-family: Bellefair;
  color: #000000;
  padding-top: 30px;
  padding-bottom: 30px;
}

.newsletter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Plus Jakarta Sans;
  color: #fff;
  padding: 10px;
  gap: 10px;
}

.newsletter-container h1 {
  font-size: 24px;
  font-family: Plus Jakarta Sans;
}

.newsletter-container a {
  color: #000;
  text-decoration: none;
  font-weight: 500;
}

.newsletter-container-button {
  border-radius: 24px;
  background: #fff;
  align-items: flex-start;
  padding: 8px 22px;
  color: #fff;
  border: 1px solid transparent;
}

/*FOOTER*/
.footer {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.10);
  color: #000;
  padding-left: 50px;
  padding-right: 50px;
  font-family: Plus Jakarta Sans;
  
}

.footer-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 50px;
  width: 70%;
  align-self: center;
}

.footer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-copyright-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 10px;
}

.footer-logo-item {
  display: flex;
  flex-direction: column;
}

.footer-logo-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-links-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.footer-nav-item {
  display: flex;
  flex-direction: column;
  
  color: #000;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: Plus Jakarta Sans;
}

.footer-nav-item a {
  text-decoration: none;
  color: #000;
}

.footer-eclipse {
  display: flex;
  height: 40px;
  padding: 10px 9px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 30px;
  background: var(--3-e-826-d, #3E826D); 
  color: #FFF;
  margin-right: 10px;
  text-align: justify;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; 
}

.footer-button {
  display: flex;
  height: 40px;
  padding: 10px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  color: #000;
  background: #fff;
  text-align: justify;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.footer-button:focus {
  outline: none;
}

.footer-button a {
  text-decoration: none;
  color: #000;
}

.icon-size {
  width: 50px;
  height: 52px;
}

.mobile-menu-icon {
  display: none; /* Hide the menu icon */
}

.footer-nav-item div {
  /* Add any additional styling for your footer links here */
}

.mobile-dropdown-content, .footer-nav-content {
  display: block; /* Show the regular navigation */
}

.footer-links-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.sidebar-toggles {
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 100;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 24px;
}

.sidebar-toggle {
  display: flex;
  position: absolute;
  top: 45%;
  left: 0; 
  
  align-items: center;
  font-weight: 500;
  font-family: Plus Jakarta Sans;
  color: #898797;
  z-index: 1;
}

.forward-arrow {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
  border-top: 2px solid #898797;
  border-left: 2px solid #898797;
  transform: rotate(135deg);
  z-index: 1;
  opacity: 0.5;
}

.sidebar-back-button {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
  border-top: 2px solid #898797;
  border-left: 2px solid #898797;
  transform: rotate(-45deg);
  z-index: 1;
  
}

.sidebar-forward-button {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
  border-top: 2px solid #898797;
  border-left: 2px solid #898797;
  transform: rotate(135deg);
  z-index: 1;
  margin-right: 2px;
}

.sidebar-header-items {
  display: flex;
  flex-direction: row;
  width: 50%;
  font-weight: 500;
  font-family: Plus Jakarta Sans;
  color: #898797;
}

.sidebar-close-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  font-weight: 500;
  font-size: 13px;
  font-family: Plus Jakarta Sans;
  color: #898797;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.resources-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: center;
  width: 60%;
}

/* SEARCH FUNCTION CSS */
.search-container {
  display: flex;
  width: 100vw;
  justify-content: center;
  font-family: Plus Jakarta Sans;
  padding-top: 30px;
  padding-bottom: 30px;
}

.search-box {
  
  border: 1px solid black;
  width: 90%;
  
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  padding: 30px;
}

.search-input {
  border: 1px solid #ccc;
  display: flex;
  width: 100%;
  border-radius: 6px;
  background: #fff;
  min-height: 50px;
}

.search-input select {
  border: none;
  border-left: 1px solid #ccc;
}

.search-input select:focus {
  border-left: 1px solid #ccc;
  outline: none;
}

.search-input input {
  border: none;
  z-index: 2;
  padding: 5px;
  width: 100%;
  background: none;
}

.search-input input:focus {
  border: none;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Add an inset box-shadow */
  outline: none; /* Remove default outline */
  background-color: #e9fff3; /* Optional: Change background color on focus */
}

.search-input button {
  display: flex;
  padding: 10px 20px;
  border: none;
  background-color: var(--almasi-green);
  color: #fff;
  width: 100px;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.results-container {
  width: 100%;
  max-height: 600px;  /* Adjust the max-height as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
}

.results-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.result-item {
  display: grid;
  grid-template-columns: 50px 4fr 1fr;
  gap: 10px;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
  align-items: center;
}

.result-item .result-id {
  text-align: left;
}

.result-item .result-filename {
  word-wrap: break-word;
  word-break: break-all;
}

.result-item .result-url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.result-url button {
  border: none;
  border-radius: 6px;
  background-color: var(--almasi-green);
  padding: 5px 8px;
  color: #fff;
}

.invite-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.invite-input-container {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  display: flex;
  background: #fff;
  align-items: stretch;
  justify-content: stretch;
  min-height: 50px;
}

.invite-input-container input {
  width: 100%;
  background: none;
  border: none;
  border-radius: 0px;
  border-right: 1px solid #ccc;
  padding: 5px;
}

.invite-input-container input:focus {
  border: none;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Add an inset box-shadow */
  outline: none; /* Remove default outline */
  background-color: #e9fff3; /* Optional: Change background color on focus */
}

.invite-input-container button {
  display: flex;
  padding: 10px 20px;
  border: none;
  background-color: var(--almasi-green);
  color: #fff;
  width: 100px;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  
}

.invite-input-container::placeholder {
  color: black;
  font-weight: 600;
}

.generated-link {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 10px;
  background: #fff;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.link-box {
  border: 1px solid #ccc;
  padding: 6px;
  background: #f0f0f0;
  border-radius: 6px;
}

.organizations-item {
  display: grid;
  grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
  align-items: center;
}

.testers-item {
  display: grid;
  grid-template-columns: 50px 1fr 1fr;
  gap: 10px;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
  align-items: center;
  
}

.pinecone-item {
  display: grid;
  grid-template-columns: 100px 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
  align-items: center;
  overflow-wrap:anywhere;
}

.pinecone-item span {
  overflow-wrap:anywhere;
  
}

.blog-container {
  width: 100%;
  max-height: 600px;  /* Adjust the max-height as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
}

.blog-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.blog-item {
  display: grid;
  grid-template-columns: 50px 1fr 1fr 1fr;
  gap: 10px;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
  align-items: center;
}

.blog-item .blog-id {
  text-align: left;
}

.blog-item .blog-filename {
  word-wrap: break-word;
  word-break: break-all;
}

.blog-item .blog-url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.blog-item .blog-delete {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.blog-delete button {
  border: none;
  border-radius: 6px;
  background-color: rgb(218, 60, 60);
  padding: 5px 8px;
  color: #fff;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid var(--almasi-green); 
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  align-self: center;
}

.carousel-container {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 60%;
  border: none;
  padding: 10px;
  align-self: center;
}

.carousel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  width: 200px;
  height: 300px;
  align-self: center;
  background: #fff;
  border-radius: 6px;
  
  box-shadow: 0px 3px 10px 0px #00000033;
  margin:1rem;
}

.carousel-content-title {
  font-size: 16px;
  text-align: center;
  width: 100%;
  height: 90px;
  max-height: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  padding: 10px;
  font-weight: 500;
}

.carousal-img {
  width: 100%;
  height: 150px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: none;
}

.carousal-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

.carousal-btn-container {
  display: flex;
  width: 100%;
  height: 20%;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 5px;
  font-weight: 600;
}

.carousal-btn {
  font-size: 13px;
  font-weight: 500;
}

.carousal-btn:hover {
  font-size: 13px;
  font-weight: 600;
}

.carousal-btn:focus {
  font-size: 13px;
}

.carousal-btn:active {
  font-size: 13px;
}

.new-blog-item-form {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  display: flex;
  background: #fff;
  align-items: stretch;
  justify-content: stretch;
  min-height: 50px;
}

.new-blog-item-form input {
  width: 50%;
  background: none;
  border: none;
  border-radius: 0px;
  border-right: 1px solid #ccc;
  padding: 5px;
}

.new-blog-item-form input:focus {
  border: none;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Add an inset box-shadow */
  outline: none; /* Remove default outline */
  background-color: #e9fff3; /* Optional: Change background color on focus */
}

.new-blog-item-form button {
  display: flex;
  padding: 10px 20px;
  border: none;
  background-color: var(--almasi-green);
  color: #fff;
  width: 100px;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  
}

.new-blog-item-input::placeholder {
  color: black;
  font-weight: 600;
}

/* Custom Carousel Controls */
.carousel-control-next, .carousel-control-prev {
  width: 5%;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  background-size: 100%, 100%;
  background-image: none;
}

.carousel-control-next-icon::after, .carousel-control-prev-icon::after {
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: var(--almasi-green);
  mask: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" fill="%23ffffff" viewBox="0 0 8 8"><path d="M2 0L0 2l2 2zM6 0v8z"/></svg>') no-repeat center;
  mask-size: contain;
}

.carousel-control-next-icon::after {
  mask: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" fill="%23ffffff" viewBox="0 0 8 8"><path d="M6 0L8 2l-2 2zM2 0v8z"/></svg>') no-repeat center;
  mask-size: contain;
}

.constrained-image {
  width: 50px;
  height: 200px;
}

.gateway-body {
  background: #F2F6F5;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  
}

.portal-logo-container {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.portal-main-container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.portal-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 70%;
  height: 90%;
}

.portal-box {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  flex-grow: 1;
  width: 300px;
  cursor: pointer;
  min-height: 500px;
  box-shadow: 0px 3px 10px 0px #00000033;
  -webkit-box-shadow: 0px 3px 10px 0px #00000033;
  -moz-box-shadow: 0px 3px 10px 0px #00000033;
}

.portal-box:hover {
  background: #fbfbfb;
}

.portal-box-content {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  padding: 14px;
  
}

.portal-box-content h2 {
  font-size: 42px;
  font-weight: 500;
  color: #3E826D;
  font-family: Bellefair;
}

/* MOBILE COMPAT */
@media only screen and (max-width: 1000px) {
  table {
    min-width: 400px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    font-size: 15px;
    margin-right: 30px;
  }
  
  th, td {
    border: 1px solid black; 
    padding: 8px; 
    text-align: left;
    
  }
  
  iframe {
    width: 350px;
    height: 200px;
  }

  .resources-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    width: 90%;
  }

  .pricing-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding: 10px;
    width: 100%;
  }

  .terms-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
    width: 90%;
    font-family: Plus Jakarta Sans;
    padding-top: 20px;
  }
  
  .terms-container h1 {
    align-self: center;
    font-size: 20px;
  }
  
  .terms-container h2 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .terms-container p {
    font-size: 15px;
  }
  
  .terms-services-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: flex-start;
    width: 100%;
    gap: 15px;
  }
  
  .terms-services-row h1 {
    font-size: 15px;
    font-weight: 500;
    width: 5%;
    align-self: flex-start;
  }
  
  .terms-services-row p {
    font-size: 15px;
    width: 95%;
    align-self: flex-start;
  }
  
  .terms-license-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: flex-start;
    width: 100%;
    gap: 15px;
  }
  
  .terms-license-row h1 {
    font-size: 15px;
    font-weight: 500;
    width: 5%;
    align-self: flex-start;
  }
  
  .terms-license-row p {
    font-size: 15px;
    width: 95%;
    align-self: flex-start;
  }
  
  .terms-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: flex-start;
    width: 100%;
    gap: 15px;
  }
  
  .terms-info {
    width: 50%;
  }
  
  .terms-row h1 {
    font-size: 15px;
    font-weight: 600;
    width: 30%;
    align-self: flex-start;
  }
  
  .terms-row p {
    font-size: 15px;
    width: 70%;
    align-self: flex-start;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
    width: 90%;
    font-family: Plus Jakarta Sans;
    padding-top: 20px;
  }

  .content-container p {
    font-size: 15px;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: flex-start;
    width: 90%;
    font-family: Plus Jakarta Sans;
    font-weight: 500;
    padding: 10px;
  }

  .faqs-container {
    width: 90%;
  }

  sidebar {
    grid-area: sidebar;
    position: absolute;
    background: #FFFFFF;
    padding: 10px;
    z-index: 2;
  }

  .sidebar-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .sidebar-header-items {
    display: flex;
    flex-direction: row;
    width: 50%;
    font-weight: 500;
    font-family: Plus Jakarta Sans;
    color: #898797;
  }

  .sidebar-toggle {
    display: flex;
    position: absolute;
    top: 45%;
    left: 1%; 
    align-items: center;
    font-weight: 500;
    font-family: Plus Jakarta Sans;
    color: #898797;
    z-index: 1;
  }

  .sidebar-close-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    font-weight: 500;
    font-size: 13px;
    font-family: Plus Jakarta Sans;
    color: #898797;
  }

  .session-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    font-family: Plus Jakarta Sans;
    overflow-y: auto;
    max-height: 400px;
    min-height: 550px;
    padding-right: 5px;
  }

  .chat-text {
    font-size: 12px;
  }

  .chatbar-box {
    width: 99%;
  }

  .chat-container {
    width: 95%;
  }

  .question-type-dropdown {
    font-size: 12px;
  }

  .button-chat {
    font-size: 13px;
  }

  .landing-banner-text {
    font-size: 13px;
  }

  .landing-banner-text h1 {
    font-size: 26px;
  }

  .landing-banner-text h3 {
    font-size: 26px;
    font-weight: 650;
  }

  .landing-banner {
    display: flex;
    height: auto;
    flex-direction: column;
    background: #fff;
    align-items: center;
    justify-content: center;
    font-family: Plus Jakarta Sans;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .landing-main3-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
  }
  
  .landing-main3-container h3 {
    font-size: 20px;
    
  }
  
  .landing-banner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 95%;
  }

  .landing-banner-button {
    align-self: center;
  }

  .landing-banner-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .landing-banner-text1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    margin-top: 20px;
    gap: 20px;
  }

  .landing-banner-text1 h1 {
    font-size: 26px;
  }

  .landing-banner-pic {
    width: 100%;
    padding-top: 56.25%; /* 9 / 16 = 0.5625 */
    background-image: url('./App/Img/banner-pic.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .landing-banner-pic1 {
    width: 100%;
    padding-top: 56.25%; /* 9 / 16 = 0.5625 */
    background-image: url('./App/Img/banner-pic1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .landing-banner-pic2 {
    width: 100%;
    padding-top: 56.25%; /* 9 / 16 = 0.5625 */
    background-image: url('./App/Img/banner-pic2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .landing-banner-pic3 {
    width: 100%;
    padding-top: 56.25%; /* 9 / 16 = 0.5625 */
    background-image: url('./App/Img/banner-pic3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .landing-main1 {
    display: flex;
    background: #3E826D;
    align-items: center;
    justify-content: center;
    font-family: Bellefair;
    color: #FFFFFF;
  }

  .icon-size {
    width: 40px;
    height: 42px;
  }

  .landing-main1-container {
    height: 95%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    padding: 10px;
    
    gap: 40px;
  }

  .landing-main1-info-container h2 {
    font-size: 18px;
  }

  .landing-main1-info-container p {
    font-size: 14px;
  }

  .landing-main1-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding: 0;
    width: 100%;
    margin-right: 0;
    gap: 10px;
  }

  .landing-main1-text h4 {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
  }

  .landing-main1-text h3 {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
  }

  .landing-main1-text p {
    text-align: center;
  }

  .input-chat {
    font-size: 13px;
  }

  .model-selector {
    opacity: 0.4;
    min-width: 75px;
    font-size: 13px;
    padding-right: 30px;
  }

  .navbar-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 5px;
  }

  .navbar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    
  }

  .navbar-item-button {
    display: flex;
    background: #3E826D;
    border: 1px solid #12A37F1A;
    border-radius: 30px;
    padding: 5px 8px 5px 8px;
    color: #fff;
    font-size: 11px;
    
  }

  .sponsor-logo {
    background-image: url('./App/Img/sponsor.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 80px;
    height: 30px;
  }


  .logo {
    background-image: url('./App/Img/newlogo.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 35px;
  }

  .footer-logo-row {
    display: flex;
    align-items: center;
    
  }

  .logo-text {
    font-family: Bellefair;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #3E826D;
    align-self: center;
  }
  
  .logo-text a {
    text-decoration: none;
    color: #3E826D;
  }

  .menu-icon {
    display: block; /* Ensure the toggle button is shown on mobile */
    cursor: pointer;
    margin-right: 10px;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    justify-content: space-evenly;
    width: 200px;
  }

  .dropdown-action-item {
    align-self: flex-start;
    cursor: pointer;
    color: #000; /* Example color */
    display: block; 
    padding: 6px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: 0;
    right: 70%;
    background-color: #F2F6F5;
    min-width: 160px;
    z-index: 1;
    margin-right: 10px;
  }

  .dropdown-content a {
    color: black;
    padding: 6px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #d4d4d4;
  }

  .navbar-item:hover .dropdown-content {
    display: block;
    margin-right: 10px;
  }

  .navbar-item .menu-icon:hover + .dropdown-content {
    display: block;
  }

  .landing-main3 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .landing-main4 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .landing-main2 {
    display: flex;
    padding: 0;
    background: #FFFFFF;
    align-items: center;
    justify-content: center;
    
    font-family: Bellefair;
    color: #000000;
  }
  
  .landing-main2-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
    overflow-y: auto;
    scrollbar-width: none;
    
  }

  .landing-main2-container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }

  .landing-main2-item {
    display: flex;
    text-align: center;
    flex-direction: row;
    padding: 10px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.10);
    color: #000;
    font-family: Plus Jakarta Sans;
  }

  .footer-copyright-row {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 13px;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
  }

  .footer-logo-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    
  }
  
  .footer-nav-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000;
    margin-left: 0;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 100%;
  }


  .footer-nav-item div {
    border-right: 1px solid #ccc;
    padding-right: 10px;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-self: center;
    margin-right: 15px;
    font-size: 13px;
  }

  .footer-nav-item div:last-child {
    border-right: none; /* Remove border from the last item */
  }

  .footer-eclipse {
    display: flex;
    height: 0;
    padding: 0;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 30px;
    background: var(--3-e-826-d, #3E826D); 
    color: #FFF;
  
    text-align: justify;
    font-family: "Plus Jakarta Sans";
    font-size: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; 
  }
  
  .footer-button {
    display: flex;
    height: 40px;
    padding: 2px 5px 2px 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    color: #000;
    background: #fff;
    text-align: justify;
    font-family: "Plus Jakarta Sans";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .footer-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }

  .footer-links-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .footer-links-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}