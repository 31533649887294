body {
  
  font-family: Plus Jakarta Sans;
  /*font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;*/
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
  /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F2F6F5;
  ;
}

.new-container {
  display: flex;
  height: 100vh;
}

.new-main {
  flex-grow: 1;
}

.sidebar-toggle-menu {
  display: flex;
  border: none;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background: none;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.sidebar-toggle-menu:hover {
  z-index: 1000;
  border: none;
  background: #eeeeee;
  border-radius: 8px;
  cursor: pointer;
}

.new-sidebar-header {
  position: fixed;
  top: 0;
  left: 0; 
  z-index: 1000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 45px;
  width: 250px;
  padding: 5px;
}

.new-logo {
  background-image: url('./App/Img/newlogo.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 28.41px;
  height: 35px;
  background-color: none;
}

.new-logo-text {
  font-family: Bellefair;
  font-size: 24px;
  color: #3E826D;
}


.new-sidebar-header-active {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 45px;
  padding: 5px;
  margin-top: 60px;
}

.new-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.new-sidebar-new-chat-button {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  background: #3E826D;
  border-radius: 24px;
  border: none;
  
  color: #fff;
  font-size: 16px;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  font-family: Plus Jakarta Sans;
}

.new-sidebar-new-chat-button:hover {
  background: #438c75;
}

.new-sidebar-menu {
  display: flex;
  flex-direction: column;
  color: #333;
  padding: 5px;
  flex-grow: 1;
}

.new-sidebar-menu-title {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  color: #979797;
  border: none;
  margin-bottom: 5px;
  padding: 20px 10px 0 10px;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}

.new-convo-icon {
  height: 25px;
  width: auto;
  color: #979797;
}

.new-convo-text {
  width: 160px;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  text-align: start;
}

.new-sidebar-footer-text {
  width: 145px;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  text-align: start;
  font-size: 14px;
  color: #333;
}

.new-menuitem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: flex-start;
  align-items: center;
  font-family: Plus Jakarta Sans;
  color: #979797;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  height: 45px;
  background: none;
  border: 1px solid transparent;
  border-radius: 8px;
  gap: 5px;
}

.new-menuitem:hover {
  border: 1px solid #D2EEE6;
}

.new-menuitem.link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: flex-start;
  align-items: center;
  font-family: Plus Jakarta Sans;
  color: #979797;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  height: 45px;
  background: none;
  border: none;
  border-radius: 8px;
  gap: 5px;
}

.new-menuitem.link:hover {
  background: #eeeeee;
}

.new-sidebar-button {
  display: flex;
  border: none;
  background: none;
  color: #979797;
  justify-content: center;
  height: auto;
  width: 25px;
  align-items: center;
  padding: 5px 0px;
}

.new-sidebar-button:hover {
  background: #eeeeee;
  border-radius: 8px;
  
}

.new-sep-line {
  border-top: 1px solid #E3E3E3;  
}

.new-sidebar-footer {
  display: flex;
  border-top: 1px solid #E3E3E3;
  flex-direction: column;
  padding: 5px;
}

.new-sidebar-profile-icon {
  background-image: url('./App/Img/Avatars.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}